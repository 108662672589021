import { useEffect } from "react";
import React from "react";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { blueGrey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#FE6B8B" },
    secondary: { main: "#FF8E53" },
    background: { default: "#ffffff" },
    button: { main: blueGrey[100], dark: blueGrey[300] },
  },
});

const Layout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-RJ2C336DGR");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ paddingTop: 1 }}>
        <Stack spacing={2}>
          <img
            src={"/logo_white.svg"}
            style={{ width: "100px", height: "50px", objectFit: "cover" }}
            alt="Logo"
          />
          {children}
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
