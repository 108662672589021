import React, { useState } from "react";

import { SearchDeckTabContext } from "./SearchDeckTabContext";

const SearchDeckTabProvider = ({ children }) => {
  const [mainDeckType, setMainDeckType] = useState("指定なし");
  const [subDeckType, setSubDeckType] = useState("指定なし");
  const [items, setItems] = useState({
    stats: {},
    data: {
      stats: [],
      decks_by_category: [],
    },
  });
  const [cardNameQueryList, setCardNameQueryList] = useState([
    { card_names: [], card_num: 1, condition: "以上" },
  ]);

  return (
    <SearchDeckTabContext.Provider
      value={{
        mainDeckType,
        setMainDeckType,
        subDeckType,
        setSubDeckType,
        items,
        setItems,
        cardNameQueryList,
        setCardNameQueryList,
      }}
    >
      {children}
    </SearchDeckTabContext.Provider>
  );
};

export default SearchDeckTabProvider;
