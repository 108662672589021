import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import LaunchIcon from "@mui/icons-material/Launch";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Divider,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { blueGrey } from "@mui/material/colors";

import CardNameForm from "./CardNameForm";
import { SearchDeckTabContext } from "./SearchDeckTabContext";

function DisplayCategory({ item }) {
  const itemsPerPage = useMediaQuery("(max-width:600px") ? 2 : 3;
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(item.decks.length / itemsPerPage);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDecks = item.decks.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{
            paddingLeft: "8px",
            borderLeft: `5px solid #FE6B8B`,
            fontWeight: "bold",
          }}
        >
          {item.category}
        </Typography>
      </Grid>
      {currentDecks.map((deck, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Card elevation={2} sx={{ border: "1px solid #bdbdbd" }}>
            <Box position="relative">
              <Link
                to={deck.deck_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={deck.thumbnail}
                  referrerPolicy="no-referrer"
                />
              </Link>
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{
                  p: "4px", // Padding inside the box for some spacing from the edges
                }}
              >
                <LaunchIcon sx={{ fontSize: 20, color: "black" }} />
              </Box>
            </Box>
            <CardContent
              style={{
                padding: "8px 8px 4px 8px",
              }}
            >
              <Grid container>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {deck.main_type} <br />
                    {deck.sub_type !== "None" && deck.sub_type} <br />
                    <Link
                      to={deck.deck_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      デッキ詳細
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" align="right">
                    {deck.date} <br />
                    {item.category} <br />
                    <Link
                      to={deck.event_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      イベント詳細
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
      </Grid>
    </>
  );
}

export default function SearchDeckTab({ startDate, endDate, dateRangeError }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    mainDeckType,
    setMainDeckType,
    subDeckType,
    setSubDeckType,
    items,
    setItems,
    cardNameQueryList,
    setCardNameQueryList,
  } = useContext(SearchDeckTabContext);
  const [mainDeckTypeOptions, setMainDeckTypeOptions] = useState([]);
  const [subDeckTypeOptions, setSubDeckTypeOptions] = useState([]);

  const handleUpdateQuery = (index, key, value) => {
    const newQueryList = [...cardNameQueryList];
    newQueryList[index][key] = value;
    setCardNameQueryList(newQueryList);
  };

  const handleDeleteQuery = (index) => {
    const newQueryList = cardNameQueryList.filter((_, i) => i !== index);
    if (newQueryList.length === 0) {
      newQueryList.push({ card_names: [], card_num: 1, condition: "以上" });
    }
    setCardNameQueryList(newQueryList);
  };

  const customFetch = async () => {
    let url = `${process.env.REACT_APP_API_URL}/search_deck`;
    const params = new URLSearchParams();

    if (startDate) params.append("start_date", startDate.toISOString());
    if (endDate) params.append("end_date", endDate.toISOString());
    if (mainDeckType && mainDeckType !== "指定なし")
      params.append("main_deck_type", mainDeckType);
    if (subDeckType && subDeckType !== "指定なし")
      params.append("sub_deck_type", subDeckType);
    params.append("card_name_query_list", JSON.stringify(cardNameQueryList));

    url = url + (params.toString() ? `?${params}` : "");

    const response = await fetch(url);
    if (!response.ok) {
      const errorBody = await response.json();
      setError(
        "ERROR: " + errorBody.detail ||
          `API returned status ${response.status}`,
      );
      setItems({
        stats: {
          earliest_date: "",
          latest_date: "",
          event_num: 0,
          result_num: 0,
        },
        data: { stats: {}, decks_by_category: [] },
      });
    } else {
      setError(null);
      const data = await response.json();
      setItems(data);
    }
  };

  const fetchData = async () => {
    if (dateRangeError) return;

    setLoading(true);

    try {
      await customFetch("search_deck", setItems, setError);
    } catch (e) {
      console.error("An unexpected error occured: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, dateRangeError]);

  useEffect(() => {
    const fetchMainDeckType = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/main_deck_type`,
      );
      if (response.ok) {
        const data = await response.json();
        setMainDeckTypeOptions(data);
      }
    };

    fetchMainDeckType();
  }, []);

  useEffect(() => {
    const fetchSubDeckType = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sub_deck_type/?main_deck_type=${mainDeckType}`,
      );
      if (response.ok) {
        const data = await response.json();
        setSubDeckTypeOptions(data);
      }
    };

    if (mainDeckType) {
      fetchSubDeckType();
    }
  }, [mainDeckType]);

  let content;
  if (error) {
    content = (
      <Typography varient="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }} width="100%">
        <CircularProgress />
      </Box>
    );
  } else {
    content = (
      <Stack rowGap={3}>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: 300, marginX: "auto" }}
          elevation={3}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>検索結果の入賞数（A）</TableCell>
                <TableCell align="right">{items.data.result_num}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>指定デッキの総入賞数（B）</TableCell>
                <TableCell align="right">
                  {items.data.total_result_num_of_same_deck_type}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>A / B</TableCell>
                <TableCell align="right">
                  {(
                    (items.data.result_num /
                      items.data.total_result_num_of_same_deck_type) *
                    100
                  ).toFixed(1)}
                  %
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {items.data.is_result_num_clipped && (
          <Alert severity="info">
            デッキ数が多いため直近の100デッキのみ表示しています。
          </Alert>
        )}
        <Grid container spacing={2}>
          {items.data.decks_by_category.map((item, index) => (
            <DisplayCategory item={item} key={index} />
          ))}
        </Grid>
      </Stack>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: { xs: 4, sm: 2 } }}>
        <Grid item xs={12} sm={11} container spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Select
                  labelId="select-label"
                  value={mainDeckType}
                  onChange={(event) => {
                    setMainDeckType(event.target.value);
                    setSubDeckType("指定なし");
                  }}
                  size="small"
                  fullWidth
                >
                  <MenuItem
                    value={"指定なし"}
                    sx={{ backgroundColor: "#eeeeee" }}
                  >
                    指定なし
                  </MenuItem>
                  {mainDeckTypeOptions.map((option) => (
                    <MenuItem value={option["name"]} key={option["name"]}>
                      {option["name"]}
                    </MenuItem>
                  ))}
                  <MenuItem value="その他" key="その他">
                    その他
                  </MenuItem>
                </Select>
              }
              label={
                <Typography variant="caption" marginRight={1} minWidth="120px">
                  メインデッキタイプ
                </Typography>
              }
              labelPlacement="start"
              sx={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
                maxWidth: 400,
              }}
            />
          </Grid>
          {subDeckTypeOptions.length > 1 && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Select
                    labelId="select-label"
                    value={subDeckType}
                    onChange={(event) => {
                      setSubDeckType(event.target.value);
                    }}
                    size="small"
                    fullWidth
                  >
                    <MenuItem
                      value={"指定なし"}
                      sx={{ backgroundColor: "#eeeeee" }}
                    >
                      指定なし
                    </MenuItem>
                    {subDeckTypeOptions.map((option) => (
                      <MenuItem value={option["name"]} key={option["name"]}>
                        {option["name"]}
                      </MenuItem>
                    ))}
                    <MenuItem value="その他" key="その他">
                      その他
                    </MenuItem>
                  </Select>
                }
                label={
                  <Typography
                    variant="caption"
                    marginRight={1}
                    minWidth="120px"
                  >
                    サブデッキタイプ
                  </Typography>
                }
                labelPlacement="start"
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  width: "100%",
                  maxWidth: 400,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CardNameForm
              queryList={cardNameQueryList}
              setQueryList={setCardNameQueryList}
              handleUpdateQuery={handleUpdateQuery}
              handleDeleteQuery={handleDeleteQuery}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            color="button"
            fullWidth
            sx={{
              height: "100%",
            }}
            onClick={() => {
              fetchData();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
      {!useMediaQuery("(max-width:600px)") && (
        <Divider sx={{ marginBottom: 3 }} color={blueGrey[200]} />
      )}
      {content}
    </>
  );
}
