import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { getCurrentUser } from "../../services/authService";
import DeckCard from "./DeckCard";
import DeckModal from "./DeckModal";
import DeckTable from "./DeckTable";
import {
  addDeck,
  deleteDeck,
  fetchCardNames,
  fetchDeckList,
  saveDeck,
  updateDeckPriority,
} from "./deckService";

function AdminPage() {
  const navigate = useNavigate();
  const user = getCurrentUser();
  const [deckList, setDeckList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataUpdateDialogOpen, setDataUpdateDialogOpen] = useState(false); // New state for data update dialog
  const [formState, setFormState] = useState({
    name: "",
    thumbnails: [],
    conditions: [{ card_name: "", num: 1 }],
    errors: { name: "", conditions: [] },
  });
  const [cardNameOptions, setCardNameOptions] = useState([]);
  const [currentCardName, setCurrentCardName] = useState("");
  const [deckToDelete, setDeckToDelete] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [currentMainDeck, setcurrentMainDeck] = useState(null);
  const [currentSubDeck, setCurrentSubDeck] = useState(null);
  const [currentParentDeck, setCurrentParentDeck] = useState(null); // サブデッキから見たメインデッキ情報

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    const loadDeckList = async () => {
      try {
        const data = await fetchDeckList();
        setDeckList(data);
      } catch (error) {
        console.error("Failed to fetch deck list:", error);
      }
    };
    loadDeckList();
  }, []);

  useEffect(() => {
    const loadCardNames = async () => {
      try {
        const data = await fetchCardNames(currentCardName);
        setCardNameOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (currentCardName.length > 0) {
      loadCardNames();
    }
  }, [currentCardName]);

  const openEditModal = (deck, subDeck = null) => {
    setcurrentMainDeck(deck);
    setCurrentSubDeck(subDeck);
    setCurrentParentDeck(subDeck ? deck : null);
    setFormState(
      subDeck
        ? {
            name: subDeck.name,
            thumbnails: subDeck.thumbnails,
            conditions: subDeck.conditions,
            errors: { name: "", conditions: subDeck.conditions.map(() => "") },
          }
        : {
            name: deck.name,
            thumbnails: deck.thumbnails,
            conditions: deck.conditions,
            errors: { name: "", conditions: deck.conditions.map(() => "") },
          },
    );
    setModalTitle(subDeck ? "サブタイプを編集" : "メインタイプを編集");
    setModalOpen(true);
  };

  const openAddModal = (mainDeckId = null) => {
    setcurrentMainDeck(null);
    setCurrentSubDeck(null);
    setCurrentParentDeck(mainDeckId ? { id: mainDeckId } : null);
    setFormState({
      name: "",
      thumbnails: [],
      conditions: [{ card_name: "", num: 1 }],
      errors: { name: "", conditions: [""] },
    });
    setModalTitle(
      mainDeckId ? "新しいサブタイプを追加" : "新しいメインタイプを追加",
    );
    setModalOpen(true);
  };

  const openDeleteDialog = (deck) => {
    setDeckToDelete(deck);
    setDeleteDialogOpen(true);
  };

  const saveChanges = async () => {
    const url = currentSubDeck
      ? `${process.env.REACT_APP_API_URL}/sub_deck_type/${currentSubDeck.id}/`
      : `${process.env.REACT_APP_API_URL}/main_deck_type/${currentMainDeck.id}/`;

    try {
      const updatedDeck = await saveDeck(url, {
        ...formState,
        main_deck_type: currentParentDeck ? currentParentDeck.id : null,
      });
      setDeckList((prevState) => {
        if (currentSubDeck) {
          return prevState.map((deck) =>
            deck.id === currentMainDeck.id
              ? {
                  ...deck,
                  sub_deck_types: deck.sub_deck_types.map((subDeck) =>
                    subDeck.id === updatedDeck.id ? updatedDeck : subDeck,
                  ),
                }
              : deck,
          );
        } else {
          return prevState.map((deck) =>
            deck.id === updatedDeck.id ? updatedDeck : deck,
          );
        }
      });
      setModalOpen(false);
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  const addNewDeck = async () => {
    const url = currentParentDeck
      ? `${process.env.REACT_APP_API_URL}/sub_deck_type/`
      : `${process.env.REACT_APP_API_URL}/main_deck_type/`;

    try {
      const newDeck = await addDeck(url, {
        ...formState,
        main_deck_type: currentParentDeck ? currentParentDeck.id : null,
      });
      setDeckList((prevState) => {
        if (currentParentDeck && currentParentDeck.id) {
          return prevState.map((deck) =>
            deck.id === currentParentDeck.id
              ? { ...deck, sub_deck_types: [...deck.sub_deck_types, newDeck] }
              : deck,
          );
        }
        return [...prevState, newDeck];
      });
      setModalOpen(false);
    } catch (error) {
      console.error("Failed to add new deck:", error);
    }
  };

  const handleDeleteDeck = async () => {
    const isMainDeck = deckToDelete.sub_deck_types; // サブデッキがある場合はメインデッキ
    const url = isMainDeck
      ? `${process.env.REACT_APP_API_URL}/main_deck_type/${deckToDelete.id}/`
      : `${process.env.REACT_APP_API_URL}/sub_deck_type/${deckToDelete.id}/`;

    try {
      await deleteDeck(url);
      setDeckList((prevState) => {
        if (isMainDeck) {
          return prevState.filter((deck) => deck.id !== deckToDelete.id);
        } else {
          // サブデッキ削除の場合、該当メインデッキのサブデッキリストを更新
          return prevState.map((deck) => {
            if (deck.id === deckToDelete.main_deck_type) {
              return {
                ...deck,
                sub_deck_types: deck.sub_deck_types.filter(
                  (subDeck) => subDeck.id !== deckToDelete.id,
                ),
              };
            }
            return deck;
          });
        }
      });
      setDeleteDialogOpen(false);
      setDeckToDelete(null);
    } catch (error) {
      console.error("Failed to delete deck:", error);
    }
  };

  const handleUpdatePriorities = async () => {
    try {
      await updateDeckPriority(deckList);
    } catch (error) {
      console.error("Failed to update priorities:", error);
    }
  };

  const handleDataUpdate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/assign_deck_types`,
      );
      if (response.ok) {
        setDataUpdateDialogOpen(true);
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error during data update:", error);
    }
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, height: "100dvh", overflow: "auto" }}
    >
      <Container maxWidth="lg" sx={{ marginBottom: 10 }}>
        <Box
          typography="h5"
          marginY={2}
          sx={{
            fontSize: "2rem",
            color: blueGrey[700],
          }}
        >
          ADMIN PAGE
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2}>
              <DeckTable deckList={deckList} setDeckList={setDeckList} />
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button variant="outlined" onClick={handleUpdatePriorities}>
                  並び順を更新
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => openAddModal(null)}
                  startIcon={<AddIcon />}
                >
                  メインタイプを追加
                </Button>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack spacing={2}>
              {deckList.map((deck, index) => (
                <DeckCard
                  deck={deck}
                  openEditModal={openEditModal}
                  openDeleteDialog={openDeleteDialog}
                  openAddModal={openAddModal}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Button
        variant="contained"
        onClick={handleDataUpdate}
        startIcon={<UploadIcon />}
        sx={{
          position: "fixed",
          bottom: 20,
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          backgroundColor: blueGrey[400],
          "&:hover": {
            backgroundColor: blueGrey[600],
          },
        }}
      >
        データ更新
      </Button>

      <DeckModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={currentMainDeck || currentSubDeck ? saveChanges : addNewDeck}
        formState={formState}
        setFormState={setFormState}
        cardNameOptions={cardNameOptions}
        setCurrentCardName={setCurrentCardName}
        title={modalTitle}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>削除確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deckToDelete && deckToDelete.sub_deck_types
              ? "このメインデッキを削除すると、関連するサブデッキもすべて削除されます。本当に削除しますか？"
              : "このデッキを削除してもよろしいですか？"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleDeleteDeck} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dataUpdateDialogOpen}
        onClose={() => setDataUpdateDialogOpen(false)}
      >
        <DialogTitle>データ更新</DialogTitle>
        <DialogContent>
          <DialogContentText>
            データ更新が開始されました。数分後に完了します。画面を閉じても構いません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDataUpdateDialogOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AdminPage;
