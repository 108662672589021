import React, { useEffect, useRef, useState } from "react";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { Typography, alpha } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { blueGrey } from "@mui/material/colors";

import CustomLabel from "./CustomLabelComponent";
import TableComponent from "./TableComponent";

export default function DeckListTab({ items, loading, error, setTabValue }) {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
        setChartHeight(chartWidth * 0.6);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [loading, chartWidth]);

  let content;
  if (error) {
    content = (
      <Typography variant="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    const colors = [
      blueGrey[700],
      blueGrey[600],
      blueGrey[500],
      blueGrey[400],
      blueGrey[300],
    ];

    content = (
      <>
        {/* 本当はこの部分を別ファイルに分離したかったけど最初の画面ロード時にアニメーションが実行されない問題があり諦め */}
        <Grid container rowGap={3}>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 0 }}>
            <TableComponent items={items} setTabValue={setTabValue} />
          </Grid>
          <Grid item xs={12} sm={6} ref={chartContainerRef}>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={items}
                  startAngle={90}
                  endAngle={-270}
                  innerRadius="60%"
                  outerRadius="90%"
                  dataKey="top16_share"
                  nameKey="type"
                  label={CustomLabel}
                  labelLine={false}
                  animationDuration={700}
                >
                  {items.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={(() => {
                        if (index < colors.length) {
                          return colors[index % colors.length];
                        } else {
                          const alphaValue =
                            1 -
                            (index + 1 - colors.length) /
                              (items.length - colors.length);
                          return alpha(colors[colors.length - 1], alphaValue);
                        }
                      })()}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </>
    );
  }

  return <div>{content}</div>;
}
