import React, { useContext, useEffect, useRef } from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { blueGrey } from "@mui/material/colors";

import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";

function AceCardDisplay({
  aceCards,
  setTabValue,
  mainDeckType,
  subDeckType,
  setMainDeckType,
  setSubDeckType,
  setCardNameQueryList,
}) {
  const imageRefs = useRef([]);
  const isXs = useMediaQuery("(max-width:600px)");
  const cardWidth = isXs ? 50 : 70;

  useEffect(() => {
    aceCards.forEach((_, idx) => {
      const img = imageRefs.current[idx];
      if (img) {
        // const imageHeight = img.naturalHeight;
        const imageHeight = img.height;

        const box = img.parentNode; // 画像の親要素を取得
        box.style.height = `${imageHeight * 0.47}px`;
      }
    });
  }, [aceCards]);

  const CustomLabel = (props) => {
    const { x, y, height, value, name } = props;
    return (
      <foreignObject
        x={x - cardWidth - 5}
        y={y - ((cardWidth / 1.35) * 0.85) / 2 + 20 / 2}
        width={cardWidth}
        height={height}
        style={{ overflow: "visible" }}
      >
        <Card sx={{ border: "1px solid #bdbdbd" }}>
          <CardActionArea
            onClick={() => {
              if (name !== "エースカードなし") {
                setMainDeckType(mainDeckType);
                if (subDeckType === "None") {
                  setSubDeckType("指定なし");
                } else if (subDeckType.includes("その他")) {
                  setSubDeckType("その他");
                } else {
                  setSubDeckType(subDeckType);
                }
                setCardNameQueryList([
                  {
                    card_names: [
                      name === "レガシーエネルギー"
                        ? "レガシーエネルギー"
                        : `${name}(ACE SPEC)`,
                    ],
                    card_num: 1,
                    condition: "ちょうど",
                  },
                ]);
                setTabValue("4");
              }
            }}
          >
            <Box position="relative">
              {name === "エースカードなし" ? (
                <Box
                  sx={{
                    width: "100%",
                    aspectRatio: "1.35/1",
                    marginBottom: "-15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0",
                    color: "#333",
                    fontSize: { xs: "0.7rem", sm: "0.8rem" },
                    paddingBottom: "15%",
                  }}
                >
                  No
                  <br />
                  Ace Spec
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  image={value}
                  style={{
                    transform: "translateY(-15%)",
                    aspectRatio: "1.35/1",
                    objectPosition: "top",
                    marginBottom: "-15%",
                  }}
                  referrerPolicy="no-referrer"
                />
              )}
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{
                  p: "1px", // Padding inside the box for some spacing from the edges
                }}
              >
                <LaunchIcon sx={{ fontSize: 10, color: "white" }} />
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      </foreignObject>
    );
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={(aceCards.length * cardWidth) / 1.35 + 40}
    >
      <BarChart data={aceCards} margin={{ right: 60 }} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis
          type="category"
          dataKey="name"
          tick={false}
          width={cardWidth + 15}
        />
        <Bar
          dataKey="share"
          fill={blueGrey[500]}
          label="false"
          barSize={isXs ? 10 : 15}
          onMouseOver={(data, index) => {}}
        >
          <LabelList
            dataKey="thumbnail"
            content={<CustomLabel />}
            position="left"
          />
          <LabelList
            dataKey="share"
            position="right"
            formatter={(value) => `${value}%`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default function AceCardTab({ items, loading, error, setTabValue }) {
  const { setMainDeckType, setSubDeckType, setCardNameQueryList } =
    useContext(SearchDeckTabContext);

  if (error) {
    return (
      <Typography variant="body1" color="error" marginY={2}>
        {error}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (items.length === 0) {
    return <p>No data found.</p>;
  }

  return (
    <Grid container spacing={3}>
      {items.map((item, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Typography variant="h6">
            {item.sub_deck_type !== "None"
              ? `${item.main_deck_type} / ${item.sub_deck_type}`
              : item.main_deck_type}
          </Typography>
          <Divider color={blueGrey[600]} sx={{ height: 2, marginBottom: 1 }} />
          <Typography
            variant="body1"
            textAlign="right"
            color={blueGrey[800]}
            marginBottom={2}
          >
            総入賞数：{item.result_num}
          </Typography>
          <Grid container spacing={1}>
            <AceCardDisplay
              aceCards={item.ace_cards}
              setTabValue={setTabValue}
              mainDeckType={item.main_deck_type}
              subDeckType={item.sub_deck_type}
              setMainDeckType={setMainDeckType}
              setSubDeckType={setSubDeckType}
              setCardNameQueryList={setCardNameQueryList}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
