import React, { useEffect, useState } from "react";

import { Autocomplete, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { blueGrey } from "@mui/material/colors";

import TableComponent from "./TableComponent";

export default function CardUsageRateTab({
  cardName,
  setCardName,
  currentCardName,
  setCurrentCardName,
  items,
  loading,
  error,
  fetchByCardName,
  setTabValue,
}) {
  const [cardOptions, setCardOptions] = useState([]);

  useEffect(() => {
    const fetchCardNames = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(
            cardName,
          )}`,
        );
        const data = await response.json();
        setCardOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (cardName.length > 0) {
      fetchCardNames();
    }
  }, [cardName]);

  let content;
  if (error) {
    if (error === "ERROR: card_nameを指定してください") {
      content = (
        <Typography varient="body1" color={"error"} marginY={2}>
          カード名を入力し，検索ボタンを押してください
        </Typography>
      );
    } else {
      content = (
        <Typography varient="body1" color={"error"} marginY={2}>
          {error}
        </Typography>
      );
    }
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    content = (
      <>
        <Typography variant="h5" align="center" marginBottom={2}>
          {currentCardName}の採用率
        </Typography>

        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                {item.sub_deck_type !== "None"
                  ? `${item.main_deck_type} / ${item.sub_deck_type}`
                  : item.main_deck_type}
              </Typography>
              <Divider
                color={blueGrey[600]}
                sx={{ height: 2, marginBottom: 1 }}
              />
              <Typography
                variant="body1"
                marginBottom={1}
                textAlign="right"
                color={blueGrey[800]}
              >
                総入賞数: {item.total_result_num}
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Box minWidth="300px">
                  <TableComponent
                    mainDeckType={item.main_deck_type}
                    subDeckType={item.sub_deck_type}
                    items={item.usage_rates}
                    setTabValue={setTabValue}
                    currentCardName={currentCardName}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8} sm={4}>
        <Autocomplete
          options={cardOptions.map((option) => option.name)}
          onInputChange={(event, newValue) => {
            setCardName(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Card Name" fullWidth />
          )}
          value={cardName}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            fetchByCardName();
            setCurrentCardName(cardName);
          }}
        >
          検索
        </Button>
      </Grid>

      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
}
